var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "15px" } },
    [
      _c(
        "el-form",
        { ref: "form" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "下拉字典" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", filterable: true },
                  on: {
                    change: function ($event) {
                      return _vm.onFilterChange($event)
                    },
                  },
                  model: {
                    value: _vm.selectFilterDropDownCid,
                    callback: function ($$v) {
                      _vm.selectFilterDropDownCid = $$v
                    },
                    expression: "selectFilterDropDownCid",
                  },
                },
                _vm._l(_vm.filterDropDown, function (item) {
                  return _c("el-option", {
                    key: item.c_id + " " + item.c_label,
                    attrs: {
                      label: _vm.getOptionLabel(item),
                      value: item.c_id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.batchAddPropOptionDialogVisible,
            title: _vm.batchAddPropOptionDialogTitle,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchAddPropOptionDialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "400px", margin: "10px 0", display: "block" },
            attrs: {
              type: "textarea",
              rows: 10,
              placeholder: "请输入标签列表",
            },
            model: {
              value: _vm.batchAddPropOptionContent,
              callback: function ($$v) {
                _vm.batchAddPropOptionContent = $$v
              },
              expression: "batchAddPropOptionContent",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.confirmBatchAddPropOption },
            },
            [_vm._v("确定批量新增")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.cancelBatchAddPropOption } }, [
            _vm._v("取消批量新增"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogPropOptionVisible
        ? _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.save },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addPropOption },
                },
                [_vm._v("单项新增")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.batchAddPropOption },
                },
                [_vm._v("批量新增")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.fullscreenLoading,
                      expression: "fullscreenLoading",
                    },
                  ],
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    data: _vm.filterClientPropOptions,
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-background": "rgba(0, 0, 0, 0.8)",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "c_id", label: "ID", width: "50" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "c_label_code",
                      label: "Code",
                      width: "350",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                attrs: { disabled: !!scope.row.c_id },
                                model: {
                                  value: scope.row.c_label_code,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "c_label_code", $$v)
                                  },
                                  expression: "scope.row.c_label_code",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3397159594
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "client_label",
                      label: "名称",
                      width: "450",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                [
                                  _vm._l(
                                    _vm.getFilterClientLabels(
                                      scope.row.client_label
                                    ),
                                    function (item) {
                                      return _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "225px",
                                              display: "inline-block",
                                            },
                                            model: {
                                              value: item.c_label,
                                              callback: function ($$v) {
                                                _vm.$set(item, "c_label", $$v)
                                              },
                                              expression: "item.c_label",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100px" },
                                              attrs: {
                                                placeholder: "请选择",
                                                disabled: !!item.c_id,
                                              },
                                              model: {
                                                value: item.c_language_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "c_language_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.c_language_id",
                                              },
                                            },
                                            _vm._l(
                                              _vm.code.Language,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.key,
                                                  attrs: {
                                                    label: item.value,
                                                    value: item.key,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { width: "50px" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteClientLabel(
                                                    scope.row,
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.showOptionLabelAdd(scope.row)
                                    ? [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-top": "10px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addClientLabel(
                                                  scope.row,
                                                  null,
                                                  "option"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("新增")]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2569736721
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "c_parent_id", label: "父Id", width: "300" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "280px" },
                                  attrs: {
                                    clearable: "",
                                    "remote-method":
                                      _vm.searchPropOptionByLabelCode,
                                    filterable: "",
                                    remote: "",
                                  },
                                  on: { change: _vm.onParentPropOptionSelect },
                                  model: {
                                    value: scope.row.c_parent_id,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "c_parent_id", $$v)
                                    },
                                    expression: "scope.row.c_parent_id",
                                  },
                                },
                                _vm._l(_vm.parentPropOption, function (item) {
                                  return _c("el-option", {
                                    key: item.c_id,
                                    attrs: {
                                      value: item.c_id,
                                      label: _vm.getParentOptionLabel(item),
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1394495588
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "c_display_order",
                      label: "排序",
                      width: "150",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input-number", {
                                attrs: { min: _vm.minValue, size: "mini" },
                                model: {
                                  value: scope.row.c_display_order,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "c_display_order", $$v)
                                  },
                                  expression: "scope.row.c_display_order",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2692922241
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "c_common_option_value",
                      label: "Common Option Value",
                      width: "150",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                model: {
                                  value: scope.row.c_common_option_value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      scope.row,
                                      "c_common_option_value",
                                      $$v
                                    )
                                  },
                                  expression: "scope.row.c_common_option_value",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      946962288
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "150" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteClientPropOption(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3349210230
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }