import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/utils/request';
import GeneralTable from '@/components/GeneralTable';
import { log } from 'util';
import checkPermission from '@/utils/permission'; // 权限判断函数
import { code } from '@/utils/code';
export default {
  name: 'Index',
  components: {
    GeneralTable: GeneralTable
  },
  data: function data() {
    return {
      loading: false,
      minValue: 0,
      fullscreenLoading: false,
      filterDropDown: [],
      selectFilterDropDownCid: null,
      selectFilterDropDown: null,
      tableName: null,
      code: code,
      dialogPropOptionVisible: false,
      batchAddPropOptionDialogVisible: false,
      batchAddPropOptionContent: null,
      clientPropOptions: [],
      parentPropOption: []
    };
  },
  computed: {
    batchAddPropOptionDialogTitle: function batchAddPropOptionDialogTitle() {
      var title = '';
      var selectFilterDropDown = this.selectFilterDropDown;
      if (selectFilterDropDown) {
        title = ' : [' + selectFilterDropDown.c_table_name + '-' + selectFilterDropDown.c_label + ']';
      }
      return '批量添加' + title;
    },
    filterClientPropOptions: function filterClientPropOptions() {
      var clientPropOptions = this.clientPropOptions;
      var array = [];
      clientPropOptions.forEach(function (item) {
        if (!item.delete) {
          array.push(item);
        }
      });
      return array;
    }
  },
  created: function created() {
    this.getFilter();
    var loading = this.$loading({
      lock: true,
      text: this.$t('loading.default'),
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    loading.close();
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    // 保存离开本页面时的查询条件，当再次进入本页面时回显
    // sessionStorage.setItem(window.location.href + '_LIST_QUERY', JSON.stringify(this.$refs.GeneralTable.getListQuery()))
    next();
  },
  watch: {},
  methods: {
    batchAddPropOption: function batchAddPropOption() {
      this.batchAddPropOptionDialogVisible = true;
    },
    confirmBatchAddPropOption: function confirmBatchAddPropOption() {
      var batchAddPropOptionContent = this.batchAddPropOptionContent;
      if (isNull(batchAddPropOptionContent)) {
        return;
      }
      var list = batchAddPropOptionContent.split(/[\n\r]/);
      console.log(list);
      var __this = this;
      list.forEach(function (item) {
        if (isNull(item)) {
          return;
        }
        var insert_option_item = __this.addPropOption();
        __this.addClientLabel(insert_option_item, item, 'option');
      });
      this.batchAddPropOptionContent = null;
      this.batchAddPropOptionDialogVisible = false;
    },
    cancelBatchAddPropOption: function cancelBatchAddPropOption() {
      this.batchAddPropOptionContent = null;
      this.batchAddPropOptionDialogVisible = false;
    },
    showOptionLabelAdd: function showOptionLabelAdd(item) {
      return isNull(item.client_label) || item.client_label.length < code.Language.length;
    },
    getFilterClientLabels: function getFilterClientLabels(client_labels) {
      var client_label = [];
      if (client_labels) {
        client_labels.forEach(function (item) {
          if (!item.delete) {
            client_label.push(item);
          }
        });
      }
      return client_label;
    },
    getClientLabelByPropId: function getClientLabelByPropId(id, call_back) {
      var _this = this;
      request({
        url: 'client-table-prop/prop-option/' + id + '/client-label',
        method: 'get'
      }).then(function (res) {
        console.log(res);
        if (typeof call_back == 'function') {
          call_back.call(_this, res.data.data);
        }
      });
    },
    getClientPropOptionByPropId: function getClientPropOptionByPropId(prop_id) {
      var _this2 = this;
      request({
        url: 'client-table-prop/prop-option/' + prop_id,
        method: 'get'
      }).then(function (res) {
        console.log(res);
        _this2.clientPropOptions = res.data.data;
        _this2.dialogPropOptionVisible = true;
        _this2.getClientLabelByPropId(prop_id, function (data) {
          var obj = {};
          data.forEach(function (item) {
            var c_label_code = item.c_label_code;
            if (isNull(obj[c_label_code])) {
              obj[c_label_code] = [];
            }
            obj[c_label_code].push(item);
          });
          var __this = this;
          this.clientPropOptions.forEach(function (item) {
            var client_label = obj[item.c_label_code];
            __this.$set(item, 'client_label', client_label || []);
          });
          console.log(this.clientPropOptions);
        });
      });
    },
    onFilterChange: function onFilterChange(value) {
      console.log(value);
      var __this = this;
      this.filterDropDown.forEach(function (item) {
        if (item.c_id == value) {
          __this.selectFilterDropDown = item;
        }
      });
      this.getClientPropOptionByPropId(value);
    },
    getOptionLabel: function getOptionLabel(item) {
      if (item.c_id == 0) {
        return item.c_table_name;
      }
      return item.c_table_name + '-' + item.c_label;
    },
    getFilter: function getFilter() {
      var _this3 = this;
      request({
        url: 'prop-option/filter',
        method: 'get'
      }).then(function (res) {
        _this3.filterDropDown = res.data;
      });
    },
    confirmPropOptionDialog: function confirmPropOptionDialog() {
      this.dialogPropOptionVisible = false;
    },
    addPropOption: function addPropOption() {
      var index = 1;
      var c_display_order = 1;
      var suffix = '';
      for (var i = 0, length = this.clientPropOptions.length; i < length; i++) {
        var _c_label_code = this.clientPropOptions[i].c_label_code;
        if (!_c_label_code) {
          this.$message.warning('数据未填写完整，不能添加');
          return;
        }
        var _code = _c_label_code.replace(/.*\./img, '');
        if (!suffix) {
          suffix = _code.replace(/\d*$/img, '');
        }
        index = Math.max(index, parseInt(_code.replace(/^[a-zA-Z]*/img, '')));
        c_display_order = Math.max(c_display_order, parseInt(this.clientPropOptions[i].c_display_order));
      }
      var selectFilterDropDown = this.selectFilterDropDown;
      var c_label_code = 'option.' + selectFilterDropDown.c_table_name + '.' + selectFilterDropDown.c_code + '.' + suffix + (index + 1);
      var add_option_item = {
        c_id: null,
        c_client_id: this.getClientId(),
        c_prop_id: null,
        c_label_code: c_label_code,
        c_parent_id: null,
        c_display_order: c_display_order + 1,
        c_common_option_value: null
      };
      var init_client_label = this.getInitClientLabel();
      init_client_label.c_label_code = c_label_code;
      init_client_label.c_language_id = this.code.Language[0].key;
      init_client_label.c_type = 'option';
      add_option_item.client_label = [init_client_label];
      this.clientPropOptions.unshift(add_option_item);
      return add_option_item;
    },
    deleteClientPropOption: function deleteClientPropOption(index, row) {
      var _this4 = this;
      var newDatas = [];
      var h = this.$createElement;
      newDatas.push(h('p', null, '是否要删除?'));
      newDatas.push(h('p', null, row.c_label_code));
      this.$confirm(h('div', null, newDatas), '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(function () {
        if (isNull(row.c_id)) {
          _this4.clientPropOptions.remove(row);
        } else {
          _this4.$set(row, 'delete', true);
        }
      }).catch(function () {});
    },
    save: function save() {
      var _this5 = this;
      var c_id = this.selectFilterDropDown.c_id;
      var check = this.checkClientPropOption();
      if (check) {
        this.$message.warning(check);
        return;
      }
      this.loading = true;
      request({
        url: 'client-table-prop/' + c_id + '/save-options',
        method: 'post',
        data: this.clientPropOptions
      }).then(function (res) {
        _this5.loading = false;
        var code = res.data.code;
        if (code == '0') {
          _this5.$message.success(res.data.message);
          _this5.getClientPropOptionByPropId(_this5.selectFilterDropDownCid);
        } else {
          _this5.$message.warning(res.data.message);
        }
        console.log(res);
      });
    },
    checkClientPropOption: function checkClientPropOption() {
      var clientPropOptions = this.clientPropOptions;
      for (var i = 0, length = clientPropOptions.length; i < length; i++) {
        if (clientPropOptions[i].delete) {
          continue;
        }
        var client_label = clientPropOptions[i].client_label;
        if (isNull(client_label)) {
          return '数据未填写完整';
        }
        for (var j = 0, size = client_label.length; j < size; j++) {
          var c_label = client_label[j].c_label;
          if (isNull(c_label)) {
            return '数据未填写完整';
          }
        }
      }
    },
    deleteClientLabel: function deleteClientLabel(item, client_label_item) {
      var _this6 = this;
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(function () {
        if (!client_label_item.c_id) {
          item.client_label.remove(client_label_item);
        } else {
          request({
            url: 'client-table-prop/client-label/' + client_label_item.c_label_code + '/delete-check',
            method: 'post'
          }).then(function (res) {
            console.log(res);
            var code = res.data.code;
            if (code == '0') {
              _this6.$set(client_label_item, 'delete', true);
            } else {
              _this6.$message({
                message: res.data.message,
                type: 'warning'
              });
            }
          });
        }
      }).catch(function () {});
    },
    onParentPropOptionSelect: function onParentPropOptionSelect(value) {
      var filterClientPropOptions = this.filterClientPropOptions;
      filterClientPropOptions.forEach(function (item) {
        item.c_parent_id = value;
      });
    },
    searchPropOptionByLabelCode: function searchPropOptionByLabelCode(label_code) {
      var _this7 = this;
      if (isNull(label_code) || label_code.length < 3) {
        return;
      }
      this.loading = true;
      request({
        url: 'client-table-prop/prop-option?label_code=' + label_code,
        method: 'get'
      }).then(function (res) {
        _this7.loading = false;
        if (res.data.code == '0') {
          _this7.parentPropOption = res.data.data;
        }
      });
    },
    getClientId: function getClientId() {
      return this.$store.state.user.clientId;
    },
    addClientLabel: function addClientLabel(clientPropItem, c_label, label_type) {
      var languages = code.Language;
      var client_label = clientPropItem.client_label;
      if (!client_label) {
        this.$set(clientPropItem, 'client_label', []);
        client_label = clientPropItem.client_label;
      }
      var client_label_language = {};
      if (client_label) {
        client_label.forEach(function (item) {
          client_label_language[item.c_language_id] = true;
        });
      }
      for (var i = 0, length = languages.length; i < length; i++) {
        if (!client_label_language[languages[i].key]) {
          var init_client_label = this.getInitClientLabel();
          init_client_label.c_label = c_label ? c_label : null;
          init_client_label.c_label_code = clientPropItem.c_label_code;
          init_client_label.c_language_id = languages[i].key;
          init_client_label.c_type = label_type || 'prop';
          client_label.push(init_client_label);
          break;
        }
      }
    },
    getInitClientLabel: function getInitClientLabel() {
      return {
        c_id: null,
        c_client_id: this.getClientId(),
        c_label: null,
        c_label_code: null,
        c_language_id: null,
        c_type: null
      };
    },
    getParentOptionLabel: function getParentOptionLabel(item) {
      return item.c_label_code + '-' + item.c_label;
    }
  }
};